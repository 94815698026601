import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import ArticleBody from './InnerHtmlStyling';

const articleSamplePropertyTypes = {
  body: PropTypes.string,
  Component: PropTypes.elementType,
};

type ArticleSampleProperties = InferProps<
typeof articleSamplePropertyTypes
>;

const ArticleBodyWithOverlay = styled.div`
  position: relative;
`;

const TruncatedArticleBody = styled(ArticleBody)`
  max-height: 14rem;
  overflow: hidden;
`;

const TextFadeOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 7rem;
  background: linear-gradient(180deg, transparent 0, white 100%);
  user-select: none;
`;

const ArticleSample = ({
  body,
  Component,
}: ArticleSampleProperties) => (
  <>
    <ArticleBodyWithOverlay>
      <TruncatedArticleBody
        dangerouslySetInnerHTML={{ __html: body }}
        className="member-content"
      />
      <TextFadeOverlay />
    </ArticleBodyWithOverlay>
    {Component && (<Component />)}
  </>
);

export default ArticleSample;
